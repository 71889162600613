<template>
  <div
    class="home"
    :style="!isNoXCX ? 'padding-top: 0;' : ''"
  >
    <template v-if="isNoXCX">
      <div class="all-title">开通数字藏品账户</div>
      <div
        class="common-back"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
          alt=""
        >
      </div>
    </template>
    <div class="list">
      <div class="list-title">填写实名认证信息</div>
      <div class="list-txt">填写的实名信息必须与登录手机号持有人一致。当前登录手机号 {{ phone }}</div>
      <div class="inp">
        <div class="send-phone">
          <input
            v-model="name"
            placeholder="输入姓名"
            type="text"
          >
        </div>
        <div class="send-phone">
          <input
            v-model="idcard_no"
            placeholder="输入身份证号（将加密处理）"
            type="text"
          >
        </div>
        <div
          class="send-btn"
          @click="accountApplyAction"
        >立即开通</div>
        <div class="send-tip flex item-flex-center">
          <img
            src="https://artimg2.artart.cn/image/20220429/76d0db7eff4f9f222370b47c182a0d56.png"
            alt=""
          >
          <div>信息安全保障中，信息仅用于身份确认</div>
        </div>
      </div>
    </div>
    <div
      class="tips flex"
      @click="agreeAction"
    >
      <img
        v-if="agree"
        src="https://artimg2.artart.cn/image/20220429/9642d8bf332eda8c58d5e82cbf1b7f1c.png"
        alt=""
      >
      <img
        v-else
        src="https://artimg2.artart.cn/image/20220429/adb79bc3fef946d7b3dab5e5f837a843.png"
        alt=""
      >
      <div>同意<span @click.stop="goAgreeAction(3)">《数字藏品用户协议》</span><span @click.stop="goAgreeAction(4)">《数字藏品隐私规则》</span>。您的姓名、身份证、手机号信息将提供给一条数字藏品技术提供方(文昌链)，仅用于数字藏品账户的开通。</div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { Image as VanImage, Toast } from "vant";
import { accountInfoApi, accountApplyApi } from "@/api/nft";
import { applyVerifyInfo } from "@/api/user";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { returnWxPayType } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const agree = ref(true);
    const name = ref("");
    const idcard_no = ref("");
    const agreeAction = () => {
      agree.value = !agree.value;
    };

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      applyVerifyInfo().then((res) => {
        if (
          res.data &&
          res.data.verify_status !== 0 &&
          res.data.verify_status !== -1
        ) {
          idcard_no.value = res.data.idcard;
          name.value = res.data.real_name;
        }
      });
      // accountInfoApi().then((res) => {});
    });

    // 获取button样式集合
    const phone = computed(() => {
      if (store.getters.phone) {
        const p = store.getters.phone;
        return `${p[0]}${p[1]}${p[2]}****${p[p.length - 4]}${p[p.length - 3]}${
          p[p.length - 2]
        }${p[p.length - 1]}`;
      } else {
        return "";
      }
    });

    let saveLock = false;
    const accountApplyAction = () => {
      // accountApplyApi
      if (!name.value) {
        Toast("请输入您的真实姓名");
        return false;
      }
      if (!idcard_no.value) {
        Toast("请输入您的身份证号");
        return false;
      }
      if (!agree.value) {
        Toast("请先阅读并同意数字藏品用户协议");
        return false;
      }
      if (saveLock) return false;
      saveLock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      accountApplyApi({
        real_name: name.value,
        idcard_no: idcard_no.value,
      })
        .then(() => {
          Toast.clear();
          Toast("开通成功");
          router.back();
          saveLock = false;
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          saveLock = false;
        });
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };
    const goAgreeAction = (type) => {
      router.push("/users/agreement?type=" + type);
    };

    return {
      agree,
      name,
      idcard_no,
      phone,
      isNoXCX: returnWxPayType() == "wechat_h5",

      agreeAction,
      accountApplyAction,
      backAction,
      goAgreeAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-top: 120px;
  padding-bottom: 198px;
  max-height: 99999px;
}
.list {
  padding-top: 10px;

  .list-title {
    padding: 34px 80px 0 80px;
    font-size: 48px;
    color: #ffffff;
  }

  .list-txt {
    padding: 32px 80px 0 80px;
    font-size: 28px;
    color: #999;
  }

  .inp {
    padding-top: 32px;
  }

  .send-phone {
    height: 80px;
    border-radius: 40px;
    border: 2px solid #333333;
    margin: 32px 78px 0 78px;

    input {
      background: none;
      outline: none;
      border: none;
      padding: 20px 38px;
      width: 100%;
      box-sizing: border-box;
      line-height: 40px;
      color: #fff;
      font-size: 28px;
    }
  }

  .send-btn {
    height: 96px;
    background: #00eeff;
    border-radius: 48px;
    text-align: center;
    margin: 32px 82px 0 82px;
    line-height: 96px;
    color: #000;
    font-size: 32px;
    font-weight: 500;
  }

  .send-tip {
    margin: 36px 82px 0 82px;
    font-size: 24px;
    color: #999;

    img {
      margin-right: 2px;
      display: block;
      width: 32px;
      height: 32px;
    }
  }

  .txt {
    margin: 46px 32px 0 32px;
    width: 686px;
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
  }
}

.tips {
  padding: 0 80px;
  margin-top: 20vh;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  line-height: 34px;

  img {
    width: 32px;
    height: 32px;
    display: block;
    margin-top: 2px;
  }

  div {
    flex: 1;
    margin-left: 10px;

    span {
      color: #96c3df;
    }
  }
}
</style>
